.SubmitTitle {
    font-size: 125%;
    font-weight: bold;
    margin-bottom: 20px;
}

.SubmitSectionTitle {
    margin-top: 20px;
    font-weight: bold;
}

.SubmitOption {
    width: 300px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-right: 10px;
    margin-top: 10px
}

.SubmitOptionRow {
    margin-top: 10px;
}

.SubmitOptionRow .SubmitQuantity,.SubmitOptionRow .react-autosuggest__container {
    width: calc(50% - 32px) !important;
}

.SubmitOptionRow:first-child .react-autosuggest__container input {
    width: 100% !important;
}

.SubmitOptionRow:first-child .react-autosuggest__container ul {
    margin-left: 10px;
    border-top: 0;
    text-align: left;
    width: calc(100% - 14px);
}

.SubmitAddIngredient, .SubmitAddOption {
    margin-left: 10px;
    margin-top: 20px;
    vertical-align: top;
    cursor: pointer;
}

.SubmitIngredient {
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10px, rgba(128,128,128,1) 10px, rgba(128,128,128,1) 100%);
    border-image-slice: 1;
    padding-bottom: 10px;
    margin-right: 10px;
    position: relative;
    border-right: 0;
    border-top: 0;
    border-left: 0;
}

.SubmitRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.SubmitRemove {
    position: absolute;
    top: -5px;
    right: -15px;
    color: white;
    padding: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    cursor: pointer;
    z-index: 100;
}

.SubmitIngredient > .SubmitRemove {
    top: -15px;
    width: 30px;
    height: 30px;
    right: -30px;
}

.SubmitSubmit {
    cursor: pointer;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
}

.Submit .react-tagsinput {
    margin-left: 10px;
    flex-grow: 1;
}

.Submit .tox {
    margin: 10px 10px 0 10px;
    height: 400px !important;
}

.SubmitMessage {
    margin-top: 10px;
    text-align: center;
}

.SubmitMessageError {
    color: red;
}

@media only screen and (max-width: 440px) {
    .SubmitOption {
        width: 100%;
    }
    .SubmitOptionRow .SubmitQuantity, .SubmitOptionRow:first-child .react-autosuggest__container {
        width: calc(100% - 32px) !important;
    }
}

.SubmitModalContent {
    padding: 0;
    max-height: 75vh;
    width: 75%;
}

.Submit {
    padding: 40px;
}

.SubmitClear {
    height: 0;
    clear: both;
}