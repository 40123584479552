.ResultListItemOption {
    display: inline;
}

.ResultListItemOption:not(:first-child) {
    font-size: 85%;
}

.ResultListItemAllergens {
    font-size: 70%;
    margin-left: 5px;
    font-style: italic;
}

.ResultListItemTitle {
    margin-top: 10px;
    font-weight: bold;
}

.ResultListItemIngredients {
    list-style-type: disc;
}

.ResultListItemName {
    font-weight: bold;
    font-size: 125%;
}

.ResultListItemTags {
    margin-top: 5px;
}

li.ResultListItem {
    cursor: pointer;
    border: 2px solid grey;
    padding: 20px;
    margin: 20px 0;
    border-radius: 20px;
    max-height: 250px;
    overflow: hidden;
}

.ResultListItem .react-tagsinput-tag {
    cursor: pointer;
}

.ResultListItemCredit {
    margin-top: 20px;
    font-size: 80%;
    display: inline-block;
}

.ResultListItemCredit::before {
    content: "Submitted by "
}

.ResultListItemButtons {
    padding: 10px 0;
}

.ResultListItemButtons button {
    cursor: pointer;
}

.ResultListItemButtons button:first-child {
    margin-right: 10px;
}

.st-inline-share-buttons {
    margin-top: 10px;
}

.ResultListItemSteps {
    word-wrap: break-word;
}

.ResultListItemSteps img {
    max-width: 100%;
    height: auto;
}

.ResultListItem b {
    font-size: 125%;
}

@media print {
    body.modal-open {
        position: static !important;
        height: 0 !important;
        overflow: hidden !important;
    }
    div.Mask {
        background-color: white;
    }
    div.ModalContent {
        position: absolute !important;
        background-color: white;
        top: 0;
        left: 0;
        width: 100% !important;
        transform: none;
        border-radius: 0;
        max-height: none;
    }
    div.st-inline-share-buttons {
        display: none !important;
    }
    .ResultListItemButtons  {
        display: none;
    }
    a.SearchAddLink {
        display: none;
    }
    div.Search {
        display: none;
    }
}
