.Mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
}

.ModalContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: 25px;
    overflow: auto;
    padding: 0;
    max-height: 75vh;
    width: 75%;
}

.ModalContent > * {
    padding: 40px;
}

body.modal-open {
    height: 100vh;
    overflow-y: hidden;
    position: fixed;
    width: 100vw;
}

@media only screen and (max-width: 440px) {
    .ModalContent {
        width: 100% !important;
        max-width: 100%;
    }
}