.ResultList {
    list-style-type: none;
    padding: 0;
    margin: 10px;
    text-align: left;
    border-top: 2px solid grey;
    margin-bottom: 0;
}

.ResultList > div > div > a:last-child > .ResultListItem {
    margin-bottom: 10px;
}

.ResultList > div > div > a {
    color: black;
    text-decoration: none;
}

.ResultListScrollLoading {
    text-align: center;
    color: grey
}

.ResultListTotal {
    color: grey;
    text-align: right;
}