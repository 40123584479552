.SearchForm {
    width: 100%;
}

.SearchForm > .SearchRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SearchForm .SearchColumn {
    display: inline-block;
}

.SearchForm > .SearchRow > label > .react-autosuggest__container {
    flex-grow: 1;
}

.SearchForm > .SearchRow > label > .react-autosuggest__container ul {
    margin-left: 10px;
    border-top: 0;
    text-align: left;
}

.SearchForm .SeachFlexibilityLabel {
    flex-grow: 0;
}

.SearchForm .SeachFlexibilityLabel > input {
    max-width: 100px;
}

.SearchForm .SearchLabelRadio > span {
    flex-grow: 1;
}

.SearchForm .SearchLabelRadio > input {
    flex-grow: 0;
}

.SearchForm .SearchMoreWrapper {
    flex-grow: 1;
    text-align: left;
    margin: 10px;
}

.SearchForm .SearchMore {
    user-select: none;
    cursor: pointer;
    display: inline-block;
}

.SearchForm button {
    margin: 10px;
    cursor: pointer;
}

.SearchForm .react-tagsinput {
    margin-left: 10px;
    flex-grow: 1;
}

.SearchAddLink {
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-decoration: none;
    color: white;
    background-color: #0075ff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 200%;
}

.SearchResultsSubscribe {
    max-width: 500px;
    margin: auto;
}

.SearchResultsSubscribe label {
    flex-wrap: wrap;
}

.SearchResultsSubscribe span {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}

.SearchResultsSubscribe input {
    flex-grow: 0;
    margin-left: 0 !important;
    width: initial !important;
    flex-grow: 1;
}

.SearchResultsSubscribe button {
    margin-left: 10px;
    cursor: pointer;
}

.SearchResultsSubscribe button.subscribed {
    background-color: green;
}

.SearchResultsSubscribe .SubscribeEmailError {
    color: red;
    margin-top: 10px;
    margin-bottom: 0;
}

@media only screen and (max-width: 382px) {
    .SearchForm .SearchLabelRadio {
        display: inline-block;
    }
    .SearchForm > .SearchRowItemsRow {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 440px) {
    .SearchResultsSubscribe input {
        width: 100% !important;
    }
    .SearchResultsSubscribe button {
        margin: 0px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    .SearchForm > .SearchRowTagsRow {
        flex-wrap: wrap;
    }
}